import React from 'react';
import './Footer.css'; // Import your CSS file
import bhavishyavani from '../Images/Bhavishyavani.svg'
import { useNavigate } from 'react-router-dom';
import brandLogo from '../Images/newLogo.svg'
import brandLogo1 from '../Images/SoulswayLogo.svg'

import Instagram from '../Images/logo-instagram.png'
import LinkedIn from '../Images/logo-linkedin.png'
import Facebook from '../Images/logo-facebook.png'

const Footer = () => {
  const navigate = useNavigate()

  return (
    <footer className="footer">
      {/* <img src={bhavishyavani} alt='brand-logo' /> */}
      {/* <h4 style={{marginTop:'-20px', fontSize:'18px'}}>Let the cards guide your soul.</h4> */}
      <div className='flex flex-col justify-center items-center'>
        <div><img className='brand-logo1' src={brandLogo1}/></div>
        <h4 className='w-3/5 text-wrap my-2 font-bold text-[#303030]'>
          Embrace the realities of love with Love Tarot.
        </h4>
      </div>
      <div className="footer-content flex items-start justify-around">
        {/* <div className="footer-section">
          <h3>Product</h3>
          <ul>
            <li>Astrologer</li>
            <li>Detailed Report</li>
            <li>Forecast Report</li>
          </ul>
        </div> */}
        <div className="footer-section text-[#303030] ">
          <h3 className='font-bold opacity-80'>Follow us</h3>
          <ul className='no-underline text-inherit font-semibold flex flex-col items-center'>
            <li>
              <a href="https://www.instagram.com/soulsway_india/" target='_blank' className='flex space-between gap-2 items-center'>
                <img
                  src={Instagram}
                  alt=""
                  className="h-3 w-3"
                />
              Instagram
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61552262880543" target='_blank' className='flex space-between gap-2 items-center'>
                <img 
                  src={Facebook} 
                  alt="" 
                  className="h-3 w-3" 
                />
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/soulswayindia/" target='_blank' className='flex space-between gap-2 items-center'>
                <img 
                  src={LinkedIn} 
                  alt="" 
                  className="h-3 w-3"
                />
                LinkedIn
              </a>
            </li>

            {/* <li>Twitter</li> */}
          </ul>
        </div>
        <div className="footer-section text-[#303030]">
          <h3 className='font-bold opacity-80'>Company</h3>
          <ul className='no-underline text-inherit font-semibold'>
            <li onClick={()=>{navigate('/terms-and-conditions')}}>Terms and Conditions</li>
            <li onClick={()=>{navigate('/privacy-policy')}}>Privacy Policy</li>
            <li onClick={()=>{navigate('/cancellation-refund-policy')}}>Cancellation and Refund</li>
            <li onClick={()=>{navigate('/contactUs')}}>FAQs & Contact Us</li>

          </ul>
        </div>
      </div>
      <hr
        className="border-dashed border-1 border-black w-full mx-auto mt-5"
      />
      <div className="footer-bottom flex flex-col gap-3 text-[#303030] text-opacity-80">
        <p  className='w-full font-semibold text-center'>&copy; Ringvo Software Private Limited 2023</p>
        <p className='w-full font-semibold text-center'>3rd floor, 75C, Park Street, Kolkata 700016</p>
        <div className='w-full flex items-center justify-center flex-wrap text-center font-semibold'>
          <a href="mailto:support@soulsway.in">support@soulsway.in</a>,
          <p className='ml-2'>+91 9147334847</p>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
