import { useEffect, useState, useRef, useCallback } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import io, { Socket } from 'socket.io-client';
import LoadingAnimation from '../utils/LoadingAnimation'
import imageCompression from 'browser-image-compression'
import axios from 'axios'

import AddAttachIcon from '../../Images/addAttachIcon.png'
import SendMessageIcon from '../../Images/sendMessageIcon.png'
import BackArrowIcon from '../../Images/backArrowIcon.png'
import BlankUserImage from '../../Images/dummyUser.webp'
import SentMessageTail from '../../Images/senderChatTail.png'
import ReceivedMessageTail from '../../Images/receiverChatTail.png'
import TimerClockIcon from '../../Images/timerClock.png'
import ChatDownIcon from '../../Images/chatDown.webp'
import { TextareaAutosize } from '@mui/material';

const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);

const Chat = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const chatContainerRef = useRef(null);

    //-------------------------chat processing---------------------------//

    const [chatOpen, setChatOpen] = useState(false)
    const [isLoadingChat, setIsLoadingChat] = useState(true);
    const type = new URLSearchParams(location.search).get('type');

    const handleExitChat = () => {
        socket?.emit('leave_room', { room, user: userType === 'user' ? 'User' : sessionData?.AlternateName });
        if (userType === 'user') {
            navigate('/session-history')
        } else {
            window.location.href = `${process.env.REACT_APP_PARTNER_FRONTEND_URL}/home`
        }
    }

    //-------------------------session processing---------------------------//

    const room = new URLSearchParams(location.search).get('chatId');
    const [sessionData, setSessionData] = useState({})
    const [iseSessionStart, setIsSessionStart] = useState(false);
    const [sessionEnded, setSessionEnded] = useState(false)

    useEffect(() => {
        const getOneSession = async () => {
            try {
                let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/getOneSession`, { room })
                setSessionData(response.data);
            } catch (error) {
                console.error(error)
            }
        }

        getOneSession()
    }, [room])
    
    //-------------------------user processing---------------------------//

    const [userType, setUserType] = useState('')
    useEffect(() => {
        if (location.pathname.split('/')[1] === 'astrologerChat') {
            setUserType('astrologer')
        } else {
            setUserType('user')
        }
    }, [])

    const { astroId, userId } = useParams()
    const [user1, setUser1] = useState({})
    const [user2, setUser2] = useState({})
    const [userStatus, setUserStatus] = useState('')

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                if (userType === 'user') {
                    setUser2({
                        name: sessionData.AlternateName,
                        profilePic: sessionData.astroImage || sessionData.profilePic || BlankUserImage,
                        status: 'offline'
                    })
                    
                } else if (userType === 'astrologer') {
                    setUser2({
                        name: sessionData.name || 'User',
                        profilePic: sessionData.profilePic || BlankUserImage,
                        status: 'offline'
                    })
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchUsers()
    }, [sessionData])

    // useEffect(() => {

    // })

    //-------------------------time calculations---------------------------//

    const [timer, setTimer] = useState(false)
    const [targetDateTime, setTargetDateTime] = useState(null)
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [timeController, setTimeController] = useState(true);

    const calculateTimeRemaining = () => {
        if (targetDateTime) {
            const now = new Date();
            let difference;
            if (timeController) {
                difference = targetDateTime - now;
            } else {
                difference = new Date(targetDateTime.getTime() + parseInt(sessionData.duration) * 60 * 1000) - now
                if(difference < 0){
                    setSessionEnded(true)
                    handleExitChat()
                }
            }

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                return { days, hours, minutes, seconds };
            } else {
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        };
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeRemaining = calculateTimeRemaining();
            console.log(timeRemaining);
            setCountdown(timeRemaining);
            if (timeRemaining?.days === 0 && timeRemaining?.hours === 0 && timeRemaining?.minutes === 0 && timeRemaining?.seconds === 0) {
                console.log("fghjklkjhgfghjkhgc")
                clearInterval(intervalId);
                setTimer(false);
                setChatOpen(false);
                setTimeController(false);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countdown]);

    useEffect(() => {
        if (sessionData && Object.keys(sessionData).length) {
            const combinedDateTimeString = `${sessionData.date} ${sessionData.slotTime}`;
            setTargetDateTime(new Date(combinedDateTimeString));
            const now = new Date();
            const difference = new Date(combinedDateTimeString) - now;
            const difference1 = new Date(new Date(combinedDateTimeString).getTime() + parseInt(sessionData.duration) * 60 * 1000) - now
            if (difference1 < 0) {
                setIsSessionStart(false)
                setSessionEnded(true);

            } else {
                if (difference < 0) {
                    setIsSessionStart(true);
                } else {
                    setTimer(true)
                }
            }

        }
    }, [sessionData])

    //-------------------------message processing---------------------------//

    const [chatHistory, setChatHistory] = useState([])
    useEffect(() => {
        const getPreviousMessage = async () => {
            try {
                let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/getPreviousMessage`, { room })
                response?.data?.message.map((msg) => { 
                    setChatHistory(prevHistory => [...prevHistory, msg]) 
                })
                setIsLoadingChat(false)
            } catch (error) {
                console.error(error)
            }
        }
        getPreviousMessage()
    }, [room]) 

    useEffect(() => {
        // message loading
    }, [])

    const handleMessageSeen = () => {
        // check if messageEndRef is in viewport
        if (messageContainerRef.current && messageContainerRef.current?.inViewport) {
            console.log("message seen!")
        }
    }

    //-------------------------chatbox processing---------------------------//

    const [isChatDownButtonVisible, setIsChatDownButtonVisible] = useState(false);
    const messageContainerRef = useRef(null)

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTo({
            top: messageContainerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
          if (messageContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
            // Check if the user is at the bottom of the container
            if (scrollTop + clientHeight >= scrollHeight - 50) {
              setIsChatDownButtonVisible(false);
            } else {
              setIsChatDownButtonVisible(true);
            }
          }
        };
    
        const containerRef = messageContainerRef.current;
        if (containerRef) {
          containerRef.addEventListener('scroll', handleScroll);
        }
    
        // Clean up the event listener when the component unmounts
        return () => {
          if (containerRef) {
            containerRef.removeEventListener('scroll', handleScroll);
          }
        };
    }, [chatOpen]);

    useEffect(() => {
        const handleResize = () => {
            scrollToBottom();
        };

        // Attach the resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [currRows, setCurrRows] = useState(0)
    const inputTextRef = useRef(null)

    //-------------------------new message processing---------------------------//

    const [newMessage, setNewMessage] = useState('')
    const [addAttachment, setAddAttachment] = useState(false);
    const [typing, setTyping] = useState(false)

    const sendMessage = (content, isFile = false) => {
        if(content === '' || sessionEnded || type === 'chatHistory'){
            return
        }
        const messageData = {
            room: room,
            astroId: astroId,
            userId: userId,
            message: {
                user: userType === 'user' ? newMessage : '',
                astrologer: userType === 'astrologer' ? newMessage : '',
                file: (userType === 'user' && isFile) ? content : '',
                astroFile: (userType === 'astrologer' && isFile) ? content : '',
                status: 'Sending...'
            }
        };
        setChatHistory(prevHistory => [...prevHistory, messageData.message]);
        socket.emit('send_message', messageData, (ack) => {
            if (ack.success) {
              console.log('Message delivered to room successfully');
              setChatHistory(prevHistory => {
                const updatedHistory = [...prevHistory];
                updatedHistory[prevHistory.length-2] = {
                    ...updatedHistory[prevHistory.length-2],
                    status: updatedHistory[prevHistory.length-2].status === 'Sent'?'':updatedHistory[prevHistory.length-2].status
                };
                updatedHistory[prevHistory.length-1] = {
                    ...updatedHistory[prevHistory.length-1],
                    status: 'Sent'
                };
                return updatedHistory;
            });
            } else {
                setChatHistory(prevHistory => {
                    const updatedHistory = [...prevHistory];
                    updatedHistory[prevHistory.length-1] = {
                        ...updatedHistory[prevHistory.length-1],
                        status: 'Retry'
                    };
                    return updatedHistory;
                });
              console.log('Failed to deliver the message');
            }
          });
        if (!isFile) setNewMessage('');
        inputTextRef.current?.focus()
    };

    let typingTimeout
    const sendRetryMessage = (content, index) => {
        console.log(content)
        if(content.status === '' || content.status === 'Sent'){
            return
        }
        const messageData = {
            room: room,
            astroId: astroId,
            userId: userId,
            message: {
                user: content.user ? content.user : '',
                astrologer: content.astrologer? content.astrologer : '',
                file: content.file? content.file: '',
                astroFile:content.astroFile ? content.astroFile : '',
            }
        };
        setChatHistory(prevHistory => {
            const updatedHistory = [...prevHistory];
            updatedHistory[index] = {
                ...updatedHistory[index],
                status: 'Sending..'
            };
            return updatedHistory;
        });
        socket.emit('send_message', messageData, (ack) => {
            if (ack.success) {
              console.log('Message delivered to room successfully');
              setChatHistory(prevHistory => {
                const updatedHistory = [...prevHistory];
                updatedHistory[index] = {
                    ...updatedHistory[index],
                    status: 'Sent'
                };
                return updatedHistory;
            });
            } else {
                setChatHistory(prevHistory => {
                    const updatedHistory = [...prevHistory];
                    updatedHistory[index] = {
                        ...updatedHistory[index],
                        status: 'Retry Failed'
                    };
                    return updatedHistory;
                });
              console.log('Failed to deliver the message');
            }
          });
    };

    const handleKeyPress = async (event) => {
        if (event.keyCode === 13) {  // Enter key pressed
            event.preventDefault();
            sendMessage(newMessage);  
            handleTypeStop(); // Stop typing after sending the message
        } else {
            startTyping();  // Start typing on any other key press
        }
    };
    
    const startTyping = () => {
        if (!typing) {
            socket?.emit('start_typing', { room });
            setTyping(true);
        }
    };
    
    const handleTypeStop = () => {
        if (typing) {
            socket?.emit('stop_typing', { room });
            setTyping(false);
        }
    };
    
    useEffect(() => {
        if (typing) {
            typingTimeout = setTimeout(() => {
                handleTypeStop();  // Stop typing after 1 second of no key press
            }, 3000);
    
        } else {
            clearTimeout(typingTimeout);
        }
        return () => clearTimeout(typingTimeout);  // Clear timeout if typing continues
    }, [newMessage]); 
    

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    //-------------------------file upload and attachment processing---------------------------//

    const imageSize =(file)=>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
    
        const promise = new Promise((resolve,reject)=>{
        reader.onload = function(e){
            const image = new Image();
            image.src = e.target.result;
            image.onload =function(){
                const height = this.height;
                const width = this.width;
                resolve({width,height})
            }
            image.onerror = reject
        }
        })
        return promise
    }
    
    const compressImage = async(file) =>{
        const imageDimenssion = await imageSize(file);
        const options = {
            maxSizeMB:1,
            maxWidthOrHeight:imageDimenssion?.width>1300?1300:imageDimenssion?.width,
            useWebWorker:true,
        }
    const compressedImage = await imageCompression(file,options);
    return compressedImage
    }

    const blobToFile = (blob, fileName) => {
        return new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
        });
    };

    const handleFileUpload = async (event) => {
        if (sessionEnded || type === 'chatHistory') {
            return;
        }
        const file = event.target.files[0];
        const image = await compressImage(file);
        const compressedFile = blobToFile(image, 'compressed_image.jpg'); 
        if (!compressedFile) return;
        if (window.confirm('Are you sure you want to upload this file?')) {
            const formData = new FormData();
            formData.append('file', compressedFile);

            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const data = response.data;
                sendMessage(data.url, true);
            } catch (error) {
                console.error('Upload failed:', error);
            }
        }
    };

    //-------------------------message component---------------------------//

    const Message = ({ index,message }) => {
        if (!message) return null

        if (message.type && message.type === 'session-message') {
            return (
                <div
                    className='w-full p-2 text-center'
                >
                    <span
                        className="text-base font-medium"
                    >
                        {message.content}
                    </span>
                </div>
            )
        }

        let messageClass = '', tailClass = '', statusClass = ''
        const sender = (message.astrologer?.length > 0 || message.astroFile?.length > 0) ? 'astrologer' : 'user'

        if (sender === userType) {
            messageClass = 'relative min-w-10 max-w-[80%] ml-auto flex justify-center p-2 bg-[#A94011] text-black rounded-2xl text-white text-wrap break-all'
            tailClass = 'w-3 h-3 absolute bottom-0 -right-1'
        } else {
            messageClass = 'relative min-w-10 max-w-[80%] mr-auto flex p-2 bg-[#E6E6E6] text-black rounded-2xl text-wrap break-all'
            tailClass = 'w-3 h-3 absolute bottom-0 -left-1 z-10'
        }

        if (message.status === 'Retry') {
            statusClass = 'text-red-500 cursor-pointer'
        }
        
        return (
            <>     
                <div className={messageClass}>
                    {
                        (message.astroFile?.length > 0 || message.file?.length > 0) ? (
                            <img
                                className='w-full h-full object-cover rounded-sm'
                                src={message.astroFile?.length > 0 && message.astroFile || message.file?.length > 0 && message.file}
                            />
                        ) : (
                            <span className="text">{message.astrologer || message.user}</span>
                        )
                    }
                    <img src={sender === userType ? SentMessageTail : ReceivedMessageTail} className={tailClass} />
                </div>
                {
                    (userType === sender) && (
                        <span 
                            className={`${statusClass} ml-auto`}
                            onClick={()=>sendRetryMessage(message,index)}
                        >
                            {message.status}
                        </span>
                    )
                }
            </>
       
        )
    }

    //-------------------------socket processing---------------------------//

    const joinRoom = () => {
        setChatOpen(true);
        socket?.emit('join_room', { room, user: userType === 'user' ? 'User' : sessionData?.AlternateName });
    }
    
    useEffect(() => {
        socket.on("receive_message", (msg) => {
            if(msg.message.user || msg.message.file || msg.message.astroFile || msg.message.astrologer){
                setChatHistory(prevHistory => [...prevHistory, msg.message]);
            }
            setUserStatus('online')
        })

        socket.on("start_typing", () => {
            setUserStatus('typing...')
        })

        socket.on('stop_typing', () => {
            setUserStatus('online')
        })

        socket.on('join_room', (data) => {
            if (data.message.split(' ')[0] === undefined || data.message.split(' ')[0] === 'undefined') {
                return
            }
            setChatHistory(prevHistory => [...prevHistory, {
                type: 'session-message',
                content: data.message
            }])
            setUserStatus('online')
        })

        socket.on('leave_room', (data) => {
            if (data.message.split(' ')[0] === undefined || data.message.split(' ')[0] === 'undefined') {
                return
            }
            setChatHistory(prevHistory => [...prevHistory, {
                type: 'session-message',
                content: data.message
            }])
            setUserStatus('offline')
        })
    }, [])


    useEffect(() => {
        if (iseSessionStart && !chatOpen && sessionData && Object.keys(sessionData).length > 0) {

            joinRoom();
        }
    }, [iseSessionStart, sessionData])

    //-------------------------mixed processing---------------------------//

    useEffect(() => {
        if (!timer && !sessionEnded) {
            setIsSessionStart(true);
        } else {
            setIsSessionStart(false);
        }
    }, [timer, sessionEnded])

    useEffect(() => {
        scrollToBottom()
    }, [chatOpen, chatHistory])

    useEffect(() => {
        console.log("timer: ", timer)
    }, [timer])

    useEffect(() => {
        if (!chatOpen) {
            let now = new Date();
            if (new Date(targetDateTime?.getTime() + parseInt(sessionData.duration) * 60 * 1000) - now < 1000) {
                setSessionEnded(true);
                const sessionStatusUpdate = async () => {
                    try {
                        let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/upadate-session-status`, { status: 'Completed', room: room })

                    } catch (error) {
                        console.error(error)
                    }
                }

                sessionStatusUpdate()

            }

        }
    }, [chatOpen])

    useEffect(() => {
        return () => {
            socket?.emit('leave_room', { room, user: userType === 'user' ? 'User' : sessionData?.AlternateName })
            if (userType === 'tarot') {
                socket.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        const textareaLineHeight = (newMessage?.length > 0 || currRows === 1) ? 24 : 36;
        const maxRows = 4;

        if (inputTextRef.current) {
            const previousRows = currRows;
            inputTextRef.current.rows = 1; // Reset number of rows in textarea
            const currentRows = Math.floor(inputTextRef.current.scrollHeight / textareaLineHeight);

            if (currentRows === previousRows) {
                inputTextRef.current.rows = currentRows;
            }

            if (currentRows >= maxRows) {
                inputTextRef.current.rows = maxRows;
                inputTextRef.current.scrollTop = inputTextRef.current.scrollHeight;
            }

            setCurrRows(currentRows < maxRows ? currentRows : maxRows);
        }
    }, [newMessage]);

    useEffect(() => {
        scrollToBottom()
    }, [currRows])

    //-------------------------rendering---------------------------//

    return (
        <>
            {
                timer ? (
                    <div className="countdown-container w-full flex flex-col items-center justify-start gap-5 text-center py-10">
                        <h1 className='text-2xl font-bold'>Your Session Starts in</h1>
                        <div className="countdown-timer flex justify-around items-center gap-4">
                            <div className="countdown-item font-semibold">
                                <span className='text-lg font-bold'>{countdown?.days}</span>
                                <p className=' '>Days</p>
                            </div>
                            <div className="countdown-item font-semibold">
                                <span className='text-lg font-bold'>{countdown?.hours}</span>
                                <p>Hours</p>
                            </div>
                            <div className="countdown-item font-semibold">
                                <span className='text-lg font-bold'>{countdown?.minutes}</span>
                                <p>Minutes</p>
                            </div>
                            <div className="countdown-item font-semibold">
                                <span className='text-lg font-bold'>{countdown?.seconds}</span>
                                <p>Seconds</p>
                            </div>
                        </div>
                    </div>
                ) 
                :
                <div className={`w-full h-[95vh] md:h-screen flex flex-col justify-between overflow-hidden`}>
                    <div className='fixed bg-white z-20 top-0 w-full h-20 flex justify-between items-center px-2 py-2 border-b border-gray-400'>
                        <div className='flex justify-between gap-2 items-center'>
                            {
                                userType === 'user' && (
                                    <img 
                                        className='w-8 h-8 rounded-lg cursor-pointer' src={BackArrowIcon}
                                        onClick={() => handleExitChat()} 
                                    />
                                )
                            }
                            <img className='w-12 h-12 border border-black object-cover rounded-lg' src={user2?.profilePic || BlankUserImage}/>
                            <div className="flex flex-col justify-between h-full">
                                <p className='text-black text-lg font-semibold truncate'>{user2?.name}</p>
                                <span className='text-sm font-medium text-[#CF511A]'>
                                    {userStatus}
                                </span>
                            </div>
                        </div>
                        {
                            userType === 'astrologer' && (
                                <button
                                    className='absolute left-1/2 p-2 bg-[#CF511A] text-white rounded-3xl'
                                    onClick={() => handleExitChat()}
                                >
                                    Exit
                                </button>
                            )
                        }
                        <div className="inline-flex h-8 w-28 text-white justify-center items-center text-sm gap-[5px] rounded-3xl bg-gradient-to-b from-[#CF511A] to-[#754C08] font-medium">
                            <img className='h-6 w-6' src={TimerClockIcon}/>
                            {countdown?.minutes} : {countdown?.seconds} left
                        </div>
                    </div>
                    <div 
                        className='relative pt-20 w-full h-full my-2 px-2 py-2 md:px-10 flex flex-col justify-start gap-1 overflow-y-auto p-2'
                        ref={messageContainerRef}
                    >
                        { 
                            !isLoadingChat ? chatHistory?.map((message, index) => {
                                return (
                                    <Message
                                        index={index}
                                        message={message}
                                        isLast={index === chatHistory.length - 1}
                                    />
                                )
                            }) 
                            :
                            <LoadingAnimation />
                        }
                        {
                            isChatDownButtonVisible && 
                            <button
                                className='sticky z-10 bottom-6 right-4 ml-auto mt-auto rounded-full w-8 h-8 flex justify-center items-center bg-[#E14C28] p-1'
                                onClick={() => scrollToBottom()}
                            >
                                <img
                                    className='w-full h-full object-cover'
                                    src={ChatDownIcon}
                                />
                            </button>
                        }
                        {
                        (type !== 'chatHistory') && sessionEnded && (
                                <div className="flex flex-col items-center justify-start gap-2">
                                    <h2 className='text-xl text-center font-semibold text-[#721c24]'>Session Ended</h2>
                                    <p className='text-lg text-center'>Your session has ended. Thank you for Attending!</p>
                                </div>
                            )
                        }
                    </div>
                    <div className="sticky bottom-0 w-full h-fit px-4 pt-2 pb-2 bg-[#707070]/[0.20] flex justify-between gap-2 items-end">
                        <div className='relative h-fit flex-1 border border-white rounded-3xl overflow-hidden flex items-center'>
                            <label 
                                htmlFor="file-upload"
                                onMouseEnter={() => setAddAttachment(true)}
                                onMouseLeave={() => setAddAttachment(false)}
                                className="h-full w-fit flex justify-center items-center" 
                            >
                                { 
                                    addAttachment && 
                                        <div className='add-attachment-btn'>
                                            {/* <div>Add Attachment</div> */}
                                            <input
                                                id="file-upload"
                                                type="file"
                                                onChange={handleFileUpload}
                                                style={{ display: 'none' }}
                                                disabled={sessionEnded || type === 'chatHistory'}
                                            />
                                        </div>
                                }
                                <img 
                                    className={`absolute left-1 bottom-2 bg-[#EF910B] w-8 h-8 rounded-full ${sessionEnded || type === 'chatHistory' ? '' : 'cursor-pointer hover:scale-105'}`}
                                    src={AddAttachIcon}
                                />
                            </label>
                            <textarea
                                ref={inputTextRef}
                                rows={currRows}
                                className={`w-full min-h-12 pl-12 pr-4 ${currRows <= 1 && 'pt-2'} focus:border-none focus:outline-none resize-none overflow-auto text-base leading-[1.5]`} 
                                placeholder="write a message..."
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={handleKeyPress}
                                onKeyUp={() => {
                                    clearTimeout(typingTimeout);  // Clear any existing timeout
                                    typingTimeout = setTimeout(handleTypeStop, 3000);  // Set a new timeout for stopping typing
                                }}
                                disabled={sessionEnded || type === 'chatHistory'}
                            />
                        </div>
                        <div className={`relative h-8 w-8 p-2 mb-2 rounded-full flex justify-center items-center bg-[#EF910B] ${!sessionEnded && type !== 'chatHistory' && 'cursor-pointer hover:scale-105'} z-10`}>
                            <img 
                                className='relative top-[1px] right-[1px] w-full h-full' 
                                src={SendMessageIcon}
                                onClick={() => sendMessage(newMessage)}
                            />
                        </div>
                    </div>
                </div>
            }
        
        </>
    )
}

export default Chat