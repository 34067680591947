import React, { useState } from "react";
import './NotFoundPage.css'
import FourZeroFourIcon from '../Images/404 icon.svg'
import { useNavigate } from "react-router-dom";
const NotFoundPage = ()=>{
    const [timeleft,setTimeleft] = useState(5);
    const navigate = useNavigate()
    function repeatEverySecond() {
        console.log('This function runs every second.');
        setTimeleft(timeleft-1);
      }
      const intervalId = setInterval(repeatEverySecond, 1000);
      setTimeout(() => {
        clearInterval(intervalId);
        navigate('/tarot-reader')
      }, 5000);

const redirect = ()=>{
    navigate('/tarot-reader')
}

    return(
        <>
        <div className="not-found-container">
            <img src={FourZeroFourIcon} alt="*"/>
            <strong style={{fontSize:'18px',marginTop:'10px'}}>Page not found. Let’s explore elsewhere! 🚀</strong>
                  <button className="tarot-icon-content-btn1" onClick={redirect}>Back to Tarot Reader Page</button>
                  <strong>Redirect in {timeleft} Sec</strong>
        </div>
        </>
    )
}

export default NotFoundPage;