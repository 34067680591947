import animationData from '../../Animations/Loading 2.json'
import Lottie from 'react-lottie';
const LoadingAnimation = ()=>{

    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
      };

    return(
        <>
           <div style={{height:'90vh',display:'flex',alignItems:'center',backgroundColor:'#c4c5c6'}}>
            <Lottie options={animationOptions} height={200} width={200} />
          </div>
        </>
    )
}

export default LoadingAnimation;