import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import NotFoundPage from './NotFoundPage';
import LoadingStrip from './utils/Loadingstrip'
import LoadingAnimation from './utils/LoadingAnimation';
import Chat from './LiveChat/Chat';


const Landing = lazy(() => import('./Landing/Landing'));
const Astrologers = lazy(()=> import('./Astrologer/Astrologers'))
const AstrologerProfile = lazy(()=> import('./Astrologer/AstrologerProfile'))
const ForcastAstroCards = lazy(()=>import('../components/Forcast/Astrocards'))
const ForcastForm = lazy(()=>import('../components/Forcast/ForcastForm'))
const Login = lazy(()=>import('../../src/components/Login/Login'))
const AstrocardsForReport = lazy(()=>import('./DetailedReport/AstrocardsForReport'))
const DetailedForm = lazy(()=>import('../components/DetailedReport/DetailedForm'))
const ContactUs = lazy(()=>import('../components/ContactUs'))
const CreateAccount = lazy(()=>import('../components/Login/CreateAccount'))
const Availability = lazy(()=>import('../components/TestingPartner/Availability'))
const BookSession = lazy(()=>import('../components/Astrologer/BookSession'))
const BookingSuccessful = lazy(()=>import('../components/HelperJsx/PaymentSuccessPage'))
const SessionHistory = lazy(()=>import('../components/Astrologer/SessionHistory'))
const SpellRemedy = lazy(()=>import('../components/Astrologer/SpellRemedy'))
const MyForcastReports = lazy(()=>import('../components/Forcast/MyForcast'))
const ForcastDetails = lazy(()=>import('../components/Forcast/ForcastDetails'))
const UserChat = lazy(()=>import('../components/LiveChat/UserChat'))
const AstrologerChat = lazy(()=>import('../components/LiveChat/AstrologerChat'))
const CoupenReedemption = lazy(()=>import('../components/Astrologer/coupenReedemption'))
const AuthenticateFlutrrUser = lazy(()=>import('../components/Authenticate/AutenticateFlutrrUser'))

const PrivacyPolicy = lazy(()=>import('../components/FooterFiles/PrivacyPolicy'))
const TermsAndConditions = lazy(()=>import('../components/FooterFiles/TermsAndConditions'))
const CancellationRefundPolicy = lazy(()=>import('../components/FooterFiles/CancellationPolicy'))
const Blogs = lazy(()=>import('../components/Blogs'))
const ReferAndEarn = lazy(()=>import('./Astrologer/ReferAndEarn'))
// Import other components and lazy-loaded components as before

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RouteStations = () => {
  const [isAstrologerChat, setIsAstrologerChat] = useState(false);
  const [isUserChat, setIsUserChat] = useState(false)

  useEffect(() => {
    const currentURL = window.location.href;
    setIsAstrologerChat(currentURL.includes('astrologerChat'));
    setIsUserChat(currentURL.includes('userChat'));
  }, []);

  return (
    <Router>
      <ScrollToTop /> {/* Ensure ScrollToTop is placed right inside Router */}
      {!isAstrologerChat && !isUserChat && <Navbar />}
      <div className="app-container">
        <Suspense fallback={<LoadingAnimation />}>
        <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/tarot-reader" element={<Astrologers />} />
                        <Route path="/authenticate" element={<AuthenticateFlutrrUser />} />
                        {/* <Route path="/astrologers" element={<Astrologers />} /> */}
                        <Route path="/astrologers/:astroId" element={<AstrologerProfile />} />
                        <Route path="/forcast" element={<ForcastAstroCards />} />
                        <Route path="/forcast/:astroId/:type" element={<ForcastForm />} />
                        {/* <Route path="/login" element={<Login />} />
                        <Route path="/login" element={<Login />} /> */}
                        {/* <Route path="/detailed-report" element={<AstrocardsForReport />} />
                        <Route path="/detailed-report/:astroId/:type" element={<DetailedForm />} /> */}
                        <Route path="/contactUs" element={<ContactUs />} />
                        <Route path="/Account" element={<CreateAccount/>} />
                        {/* <Route path="/Availability" element={<Availability />} /> */}
                        <Route path="/book-session/:astroId/:type" element={<BookSession />} />
                        <Route path="/session-booked-successful/:astroId" element={<BookingSuccessful />} />
                        <Route path="/session-history" element={<SessionHistory />} />
                        <Route path="/spell-remedy" element={<SpellRemedy />} />
                        <Route path="/myforcast-reports/:userId" element={<MyForcastReports />} />
                        <Route path="/myforcast-reports-details/:userId" element={<ForcastDetails />} />
                        <Route path="/userChat/:astroId/:userId" element={<Chat />} />
                        <Route path="/astrologerChat/:astroId/:userId" element={<Chat />} />
                        {/* <Route path="/chat/:astroId/:userId" element={<Chat />} /> */}
                        <Route path="/coupen-reedemption/:astroId/:userId" element={<CoupenReedemption />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
                        <Route path="/terot-blogs" element={<Blogs />} />
                        <Route path="/refer-and-earn" element={<ReferAndEarn />} />
                        <Route path="*" element={<NotFoundPage />} />

                    </Routes>
        </Suspense>
      </div>
      {!isAstrologerChat && !isUserChat && <Footer />}
    </Router>
  );
};

export default RouteStations;
