import React, { useRef, useState } from 'react';
import './navbar.css'; // Make sure the path to your CSS file is correct
// import './navbar1.css'
import brandIcon from '../Images/Bhavishyavani.svg';
import MyprofileIcon from '../Images/my-profile.svg';
import profileIcon from '../Images/profile1.svg';
import forcastIcon from '../Images/forecast.svg';
import reportIcon from '../Images/detailedreport.svg'
import historyIcon from '../Images/history.svg';
import astroBlog from '../Images/Cards.svg';
import contactUs from '../Images/smsedit.svg'
import logoutIcon from '../Images/logout.svg'
import dropdownIcon from '../Images/dropdownIcon.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import bhavishyawani from '../Images/brandIconTransBackground.svg'
import { useSelector, useDispatch } from 'react-redux';
import { freeSession, getUserDetails, } from '../Stores/Actions/userAction';
import { queryAllByAltText } from '@testing-library/react';
import brandLogo from '../Images/newLogo.svg';
import brandLogo1 from '../Images/SoulswayLogo.svg';
import LoginPopup from './Login/LoginPopup';




const Navbar = () => {
  const { userDetails } = useSelector((state) => state.user);
  const { tab } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openaccountPopup, setOpenaccountPopup] = useState(false);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false)
  const [explorePopup, setExplorePopup] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const loginToken = localStorage.getItem('loginToken')
  const profileMenuRef = useRef(null);
  // const userId = localStorage.getItem('userId')
  const type = 'edit-account'
  const [userData, setUserData] = useState();
  const referCode = localStorage.getItem('referCode')
  const referCode2 = sessionStorage.getItem('referCode')
  const userType2 = sessionStorage.getItem('userType')
  const navigate = useNavigate()
  const referCode1 = window.location.search.split('refercode=')[1]?.split('&')[0] || null;
  const userType1 = window.location.search.split('campaign=')[1]?.split('&')[0] || null;
  useEffect(()=>{
    if(userType2 != 'null'){
       localStorage.setItem('userType',userType2);
       sessionStorage.setItem('userType',userType2)
     }
     else{
       sessionStorage.setItem('userType',userType1)
       localStorage.setItem('userType',userType1)
     }
   },[userType1,userType2])

useEffect(()=>{
 if(referCode2 != 'null'){
    localStorage.setItem('referCode',referCode2);
    sessionStorage.setItem('referCode',referCode2)
  }
  else{
    sessionStorage.setItem('referCode',referCode1)
    localStorage.setItem('referCode',referCode1)
  }
},[referCode1,referCode])
 
 
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_FRONTEND_URL}`,
    // Other custom configurations...
  });

  api.interceptors.request.use(
    (config) => {
      if (loginToken) {
        config.headers['Authorization'] = `Bearer ${loginToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const [formData, setFormData] = useState({});

  const onClose = () => {
    setLoginPopupOpen(false);
  }

  useEffect(() => {
    // let queryUrl = queryString;
    // let updatedurl = queryString.split('?');
    // // console.log(updatedurl)
    // if(updatedurl.length>2){
    //   queryUrl = `?${updatedurl[2]}`
    // }
    // const getUrlParameter = (name) => {
    //   name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    //   const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    //   const results = regex.exec(queryString);
    //   return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    // };

    const userData = async () => {
      try {
        let response = await api.post(`${process.env.REACT_APP_SERVER_URL}/getUser`);
        setFormData(response.data.data)
      } catch (error) {
        localStorage.clear();
        console.error(error);
      }
    }
    userData()

  }, [loginToken]);

  useEffect(() => {
    const getFreeSession = async () => {
      try {
        let response = await api.post(`${process.env.REACT_APP_SERVER_URL}/get-free-session`);
        if(response.data.message === 'No Free Session Found'){
          dispatch(freeSession(true))
        }else{
          dispatch(freeSession(false))
        }
      } catch (error) {
        localStorage.clear();
        console.error(error);
      }
    }
    getFreeSession()

  }, [loginToken]); 

  useEffect(() => {
    dispatch(getUserDetails(formData))
  }, [formData])

  useEffect(()=>{
    setActiveMenuItem(tab)
  },[tab])


  // useEffect(() => {
  //   if (loginToken && loginToken.length > 0) {
  //     const token = localStorage.getItem('loginToken')
  //     const getUser = async () => {
  //       try {
  //         userAuth(token);
  //         let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getUser`)

  //         setUserData(JSON.stringify(response.data.data))
  //       } catch (error) {
  //         let errorMessage = error.response.data.message;
  //         if (errorMessage == 'Unauthorized: Invalid token') {
  //           localStorage.clear()
  //         }
  //       }

  //     }
  //     getUser()
  //   }
  // }, [loginToken])

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
      navigate(`/${menuItem}`)
    // Close the mobile menu when a menu item is clicked
    setIsMobileMenuOpen(false);
  };

  const logOut = () => {
    if (loginToken && loginToken.length > 0) {
      toast(`${'Log Out Successful'}`, { type: 'success' });
      localStorage.clear();
      setFormData({})
      setOpenaccountPopup(false)
      navigate('/')
    }
  }

  useEffect(() => {
    // Step 2: Add event listener to the document to listen for clicks outside the profile menu
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setOpenaccountPopup(false); // Close the menu if the click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Clean up
    };
  }, []);




  return (
    
    <>
      <div className="navbar-wrapper">
        
        <nav className="navbar">
          <div className="container">
            {/* Your logo */}
            {/* <img className='hand-icon' src={bhavishyawani} alt="BrandLogo" /> */}
            <p className='brand-name' onClick={() => window.location.href = '/'}><img className='brand-logo-navbar' src={brandLogo1} alt="BrandLogo" /></p>

            {/* Mobile menu toggle button */}
            <div className="mobile-menu-toggle">
              <ul className={`nav-list mobile-nav-list ${isMobileMenuOpen ? 'show' : ''}`}>
                <li><a className={activeMenuItem === 'Home' ? 'active' : ''} onClick={() => handleMenuItemClick('')}>Home</a></li>
                <li><a className={activeMenuItem === 'tarot-reader' ? 'active' : ''} onClick={() => handleMenuItemClick('tarot-reader')}>Tarot Reader</a></li>
                <li><a className={activeMenuItem === 'refer-and-earn' ? 'active' : ''} onClick={() => handleMenuItemClick('refer-and-earn')}>Refer & Earn</a></li>
                <li><a className={activeMenuItem === 'session-history' ? 'active' : ''} onClick={() => handleMenuItemClick('session-history')}>Session History</a></li>
                <li><a className={activeMenuItem === 'spell-remedy' ? 'active' : ''} onClick={() => handleMenuItemClick('spell-remedy')}>
                  <span className='relative'>
                    Spell/Remedy
                    <span className='h-1 w-1 rounded-full bg-[#E25E3E] absolute top-0 right-0'></span>
                  </span>
                </a></li>
                <li><a className={activeMenuItem === 'terot-blogs' ? 'active' : ''} onClick={() => handleMenuItemClick('terot-blogs')}>Tarot Blog</a></li>
                {/* <li className='explore' onMouseEnter={() => setExplorePopup(true)}
                  onMouseLeave={() => setExplorePopup(false)}>
                 Explore
                   <sub>
                  <img src={dropdownIcon} alt="BrandLogo"/>
                   </sub>
                {explorePopup && <div className='explore-popup'>
                  <ul className='explore-popup-list-item'>
                    <li>Vedic Astrology
                      <hr />
                    </li>
                    <li>KP & Nadi Astrology
                      <hr />
                    </li>
                    <li>Numerology
                      <hr />
                    </li>
                    <li>Palmistry
                      <hr />
                    </li>
                    <li>Vaastu Consultancy
                      <hr />
                    </li>
                    <li>Prashna Kundali
                      <hr />
                    </li>
                    <li>Nakshatra
                      <hr />
                    </li>
                    <li>Nakshatra</li>
                  </ul>
                </div>}
                </li> */}
                <li><a className={activeMenuItem === 'contactUs' ? 'active' : ''} onClick={() => handleMenuItemClick('contactUs')}>Contact Us</a></li>
              </ul>
            </div>


            <div className="sign-up-button"
              onMouseEnter={() => setOpenaccountPopup(true)}
              onMouseLeave={() => setOpenaccountPopup(false)}
            >
              <img src={MyprofileIcon} alt="profile" style={{padding:'2px' }}/>
              <div style={{padding:'2px'}}>My Profile</div>
            </div>


            <div className="sign-up-button-mobile"
              onClick={() => setOpenaccountPopup(!openaccountPopup)}
            >
              <img className='profile-img' src={MyprofileIcon} alt="profile"/>
              {/* <div className='btn-profile'>My Profile</div> */}
            </div>



          </div>
        </nav>

        {/* Mobile menu slider */}
        <div className='mobile-menu-slider'>
          <li><a className={activeMenuItem === 'Home' ? 'active' : ''} onClick={() => handleMenuItemClick('')}>Home</a></li>
          <li><a className={activeMenuItem === 'tarot-reader' ? 'active' : ''} onClick={() => handleMenuItemClick('tarot-reader')}>Tarot Reader</a></li>
          <li><a className={activeMenuItem === 'spell-remedy' ? 'active' : ''} onClick={() => handleMenuItemClick('spell-remedy')}>
            <span className='relative'>
              Spell/Remedy
              <span className='h-1 w-1 rounded-full bg-[#E25E3E] absolute top-0 right-0'></span>
            </span>
          </a></li>
          <li><a className={activeMenuItem === 'session-history' ? 'active' : ''} onClick={() => handleMenuItemClick('session-history')}>Session History</a></li>
          <li><a className={activeMenuItem === 'refer-and-earn' ? 'active' : ''} onClick={() => handleMenuItemClick('refer-and-earn')}>Refer & Earn</a></li>
          <li><a className={activeMenuItem === 'terot-blogs' ? 'active' : ''} onClick={() => handleMenuItemClick('terot-blogs')}>Tarot Blog</a></li>
          <li><a className={activeMenuItem === 'contactUs' ? 'active' : ''} onClick={() => handleMenuItemClick('contactUs')}>Contact Us</a></li>
        </div>
      </div>
      {
        openaccountPopup && (
          formData && Object.keys(formData).length > 0 ? (
            <div ref={profileMenuRef} className='container-myprofile'>
              <div
                className='myprofile-popup'
                onMouseEnter={() => setOpenaccountPopup(true)}
                onMouseLeave={() => setOpenaccountPopup(false)}
                onClick={() => setOpenaccountPopup(!openaccountPopup)}
              >
                <div className='profile-heading'>MY PROFILE</div>
                <ul className='profile-details-container px-4 pb-4'>
                  <li className='profile-list-item' onClick={() => navigate(`/Account`)}>
                    <img src={profileIcon} alt="profile" />
                    <div>My Account Details</div>
                  </li>
                  {/* <li className='profile-list-item' onClick={() => navigate(`/session-history`)}>
                    <img src={historyIcon} alt="profile" />
                    <div>My Session Details</div>
                  </li>
                  <li className='profile-list-item' onClick={() => navigate(`/terot-blogs`)}>
                    <img src={astroBlog} alt="profile" />
                    <div>Tarot Blog</div>
                  </li>
                  <li className='profile-list-item' onClick={() => navigate(`/ContactUs`)}>
                    <img src={contactUs} alt="profile" />
                    <div>Contact Us</div>
                  </li> */}
                  <li className='profile-list-item' onClick={logOut} style={{marginTop:'15px'}}>
                    <img src={logoutIcon} alt="profile" />
                    <div>Log Out</div>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className='container-myprofile'>
              <div
                className='myprofile-popup-login'
                onMouseEnter={() => setOpenaccountPopup(true)}
                onMouseLeave={() => setOpenaccountPopup(false)}
                // onClick={() => setOpenaccountPopup(!openaccountPopup)}
              >
                <div className='profile-heading-login'>WELCOME TO SOULSWAY</div>
                <button className='login-btn' onClick={() => setLoginPopupOpen(true)}>Log In</button>
              </div>
            </div>
          )
        )
      }


      <ToastContainer />
      {loginPopupOpen && <LoginPopup onClose={onClose} />}
    </>
  );
};

export default Navbar;

