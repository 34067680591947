import { PAYMENT_AMOUNT, USER_DETAILS_GET, USER_SESSION_TYPE,TAB_TYPE, FREE_SESSION } from "../Types/userTypes";

const userState = {
    userDetails:{},
    sessionType:'',
    amount:249,
    tab:'',
    isFreeSessionAvailable:true,
}

export const userReducer = (state=userState,action) =>{
    const {type, payload} = action;
    if(type === USER_DETAILS_GET){
        return {
            ...state,
            userDetails:payload.userData
        }
    }
    if(type === USER_SESSION_TYPE){
        return{
            ...state,
            sessionType:payload.sessionType
        }
    }
    if(type === PAYMENT_AMOUNT){
        return{
            ...state,
            amount:payload.amount
        }
    }
    if(type === TAB_TYPE){
        return{
            ...state,
            tab:payload.tabType
        }
    }
    if(type === FREE_SESSION){
        return{
            ...state,
            isFreeSessionAvailable:payload.session
        }
    }
  
    // if(type === USER_BOOKING_DETAILS_GET){
    //     return{
    //         ...state,
    //         bookingDetails:payload.bookingDetails
    //     }
    // }
    return state
}