import { USER_DETAILS_GET, USER_SESSION_TYPE, PAYMENT_AMOUNT, TAB_TYPE, FREE_SESSION, } from "../Types/userTypes";

export const getUserDetails = (datas) =>{
    return (dispatch)=>{
        dispatch({
            type: USER_DETAILS_GET,
            payload: {
                userData: datas,
            }
        })
    }
}
export const getSessionType = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: USER_SESSION_TYPE,
            payload: {
                sessionType: data,
            }
        })
    }
}
export const paymentAmount = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: PAYMENT_AMOUNT,
            payload: {
                amount: data,
            }
        })
    }
}
export const tabType = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: TAB_TYPE,
            payload: {
                tabType: data,
            }
        })
    }
}

export const freeSession = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: FREE_SESSION,
            payload: {
                session: data,
            }
        })
    }
}


// export const getBookingDetails = (data) =>{
//     return (dispatch)=>{
//         dispatch({
//             type: USER_BOOKING_DETAILS_GET,
//             payload: {
//                 bookingDetails: data,
//             }
//         })
//     }
// }
// export const bookingDetailsClear = (data) =>{
//     return (dispatch)=>{
//         dispatch({
//             type: USER_BOOKING_DETAILS_CLEAR,
//         })
//     }
// }