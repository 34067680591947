import React, { useState } from "react";
import './LoginPopup.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPopup = ({ onClose, proceedToCoupen, onClose1}) => {
  const [mobile, setMobile] = useState('');
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const userType = sessionStorage.getItem('userType')
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const senOtpEmail = async (e) => {
    e.preventDefault();
    if(!isChecked){
      toast(`Please click on the checkbox before Proceeding`, { type: 'warning' });
      return;
    }
    try {
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/send-otp`, { mobile });
      if (response.data.message) {
        toast(`${response.data.message}`, { type: 'success' });
        setIsOtpSend(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      senOtpEmail(event);
    }
  };
  const createNewAccount = async (e) => {
    e.preventDefault();
    try{
        let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user-verify`,{mobile:mobile,otp:otp,userType:userType});
        if(response.data.loginToken){
            localStorage.setItem('loginToken',response.data.loginToken);
            toast(`${response.data.message}`, { type: 'success' });
            if(!onClose1){
              onClose();
            } 
            if(proceedToCoupen){
              proceedToCoupen()
            }
            if(onClose1){
              onClose1()
            }
        }
    }catch(error){
      let errorMessage=(error?.response?.data?.message)
      toast(`${errorMessage}`, { type: 'error' });
        console.error(error)
    }
  };

  const handleKeyPress1 = (event) => {
    if (event.key === 'Enter') {
      createNewAccount(event);
    }
  };

  return (
    <> 
      <ToastContainer />
      {!isOtpSend ? (
        <div className="login-confirmation-popup">
          <form onSubmit={senOtpEmail}>
            <div className="login-popup-content">
              <div className="login-popup-header">
                <h2>Welcome To Soulsway</h2>
                <div className="login-email-title">Enter Your Mobile No</div>

              </div>
              <hr />
              <div className="login-details-container">
                <input value={mobile} type="text" placeholder="mobile number" onChange={(e) => setMobile(e.target.value)} onKeyPress={handleKeyPress} className="login-input" required />
              </div>
              <div>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        This is my active WhatsApp number
      </label>
    </div>
              <div className="login-details-container">
              <button style={{backgroundColor:"gray"}} onClick={onClose} className="login-confirmation-button">Close</button>

                <button className="login-confirmation-button" type="submit">Continue with Mobile</button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="login-confirmation-popup">
          <form onSubmit={createNewAccount}>
            <div className="login-popup-content">
              <div className="login-popup-header">
                <h2>Verify Your Account</h2>
                {/* <button onClick={onClose} className="close-button">X</button> */}
              </div>
              <p style={{textAlign:'center'}}>OTP has been sent to your <strong>WhatsApp number</strong>. Please enter to continue.</p>
              <hr />
              <div className="login-details-container">
                <input value={otp}  placeholder="Enter code" onChange={(e) => setOtp(e.target.value)} onKeyPress={handleKeyPress1} className="login-input" required />
              </div>
              <div className="login-details-container">
              <button style={{backgroundColor:"gray"}} onClick={onClose} className="login-confirmation-button">Close</button>

                <button className="login-confirmation-button" type="submit">Continue</button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default LoginPopup;
